import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import Button from '../../Common/Button/Button';
import QuotesList from './QuotesList';
import NoQuotes from './NoQuotes';
import EligibilitySummaryButton from '../../Common/EligibilitySummaryButton';
import QuoteResultsToolbar from './QuoteResultsToolbar/QuoteResultsToolbar';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import LoadingSpinner from '../../Common/Loading/LoadingSpinner';
import './quoteResults.scss';

class QuoteResults extends React.Component {
  constructor() {
    super();
    this.state = {
      showAllQuotes: false,
      showFullQuote: true,
      showSchemes: false,
      hasQuoteSelected: false
    };
  }

  componentDidMount() {
    this.setState({
      showAllQuotes: this.props.appStore.quotingStore.hasViewedOptions
    });
    const quote = this.props.quotes.find((quote) => !!quote.QuoteeUid);
    if (quote) this.props.appStore.quotingStore.getNewCarOffers(quote.QuoteeUid);
    this.updateHasQuoteSelected(this.props.quotes);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.quotes !== this.props.quotes) {
      this.updateHasQuoteSelected(this.props.quotes);
    }
  }

  updateHasQuoteSelected(quotes) {
    const hasQuoteSelected = quotes.some((quote) => quote.isChecked);
    this.setState({ hasQuoteSelected });
  }

  handleViewMoreQuotesClick = () => {
    this.setState(
      (prevState) => ({
        showAllQuotes: !prevState.showAllQuotes
      }),
      () => {
        // Why do we need to store this in the store?
        this.props.appStore.quotingStore.setHasViewedOptions(this.state.showAllQuotes);
      }
    );
  };

  handleToggleShowSchemes = () => {
    this.setState(
      (prevState) => ({
        showSchemes: !prevState.showSchemes
      }),
      () => {
        window.ga &&
          window.ga(
            'send',
            'event',
            'QuotesListView',
            `ShowQuoteSchemes${this.state.showSchemes ? 'TurnedOn' : 'TurnedOff'}`
          );
      }
    );
  };

  handleToggleShowFullQuotes = () => {
    this.setState(
      (prevState) => ({
        showFullQuote: !prevState.showFullQuote
      }),
      () => {
        window.ga &&
          window.ga(
            'send',
            'event',
            this.props.appStore.repExampleStore.repExampleSelected
              ? 'RepExSelectRepresentativeQuote'
              : 'QuotesListView',
            this.props.appStore.repExampleStore.repExampleSelected
              ? 'ShowFullQuote'
              : `ShowFullQuotes${this.state.showFullQuote ? 'TurnedOn' : 'TurnedOff'}`
          );
      }
    );
  };

  handleSendDeal = () => {
    this.props.onSendDeal(
      _.filter(this.props.quotes, (quote) => quote.isChecked),
      this.props.customerType
    );
    window.ga &&
      window.ga(
        'send',
        'event',
        this.props.isQuickQuote ? 'QuickQuoteResults' : 'QuoteResults',
        'ButtonPress',
        'SendDeal'
      );
  };

  render() {
    const {
      isRequote: isRequotingPage = false,
      onChangeCosts,
      onCheckEligibilityClick,
      onCompare,
      hasCheckedEligibility,
      enableSendDeal,
      customerType
    } = this.props;
    const { showAllQuotes, showFullQuote, hasQuoteSelected, showSchemes } = this.state;

    let quotes = this.state.showAllQuotes
      ? this.props.quotes
      : _.filter(this.props.quotes, (product) => {
          let shouldShowRegardless = false;

          if (product.Errors) {
            product.Errors.forEach((error) => {
              if (error.Number === 544 && this.props.vehicleClass === 'lcv') {
                shouldShowRegardless = true;
                error.Message = error.Message.includes('£')
                  ? error.Message
                  : error.Message.replace(
                      this.props.t('QuoteResults.adding_the'),
                      this.props.t('QuoteResults.adding_the_£')
                    );
              } else if (error.Number === 0) {
                shouldShowRegardless = true;
              }
            });
          }

          // TECH DEBT: This is a temporary fix to show BDK products in the quote results
          return (product.isVisible || product.FunderCode === 'BDK') && (!product.Errors || shouldShowRegardless);
        });

    return (
      <Panel>
        {isRequotingPage && <PanelHeader noBorder>{this.props.t('QuoteResults.new_rate_quote')}</PanelHeader>}
        {!isRequotingPage && (
          <>
            <PanelHeader>{this.props.t('QuoteResults.quotes')}</PanelHeader>
            <QuoteResultsToolbar
              customerType={customerType}
              onEditQuote={onChangeCosts}
              onViewAllQuotes={this.handleViewMoreQuotesClick}
              showAllQuotes={showAllQuotes}
              hasCheckedEligibility={hasCheckedEligibility}
              onCheckEligibility={onCheckEligibilityClick}
              enableSendDeal={enableSendDeal}
              onSendDeal={this.handleSendDeal}
              onToggleShowSchemes={this.handleToggleShowSchemes}
              showSchemes={showSchemes}
              onToggleShowFullQuotes={this.handleToggleShowFullQuotes}
              showFullQuote={showFullQuote}
              onCompareQuotes={onCompare}
              hasQuoteSelected={hasQuoteSelected}
            />
          </>
        )}
        {this.props.hasCheckedEligibility &&
          this.props.personalScorePercentage !== null &&
          this.props.craScorePercentage !== null && (
            <EligibilitySummaryButton
              onClick={() =>
                this.props.onEligibilitySummaryClick(this.props.personalScorePercentage, this.props.craScorePercentage)
              }
            />
          )}
        <PanelContent noPadding={true}>
          {this.props.isLoading ? (
            <div className="quoteResults__loading">
              <LoadingSpinner size="large" />
            </div>
          ) : quotes.length ? (
            <div>
              <QuotesList
                quotes={quotes}
                hasAdjustedBalloon={this.props.hasAdjustedBalloon}
                hasCheckedEligibility={this.props.hasCheckedEligibility}
                onProceed={this.props.onProceed}
                schemesVisible={this.state.showSchemes}
                onQuoteSelect={this.props.onQuoteSelect}
                onProductNameClick={this.props.onProductNameClick}
                enableShowroomCfc={this.props.enableShowroomCfc}
                chosenQuoteId={this.props.chosenQuoteId}
                onSave={this.props.onSave}
                onEligibilityMatchClick={this.props.onEligibilityMatchClick}
                isQuickQuote={this.props.isQuickQuote}
                isCustomerQuote={this.props.isCustomerQuote}
                onSortQuotes={this.props.onSortQuotes}
                sortField={this.props.sortField}
                sortDirection={this.props.sortDirection}
                proceedingState={this.props.proceedingState}
                vehicleClass={this.props.vehicleClass}
                customerType={this.props.customerType}
                showCombinedQuoteClick={this.props.showCombinedQuoteClick}
                proceedToBreakDown={this.props.proceedToBreakDown}
                showFullQuote={this.state.showFullQuote}
                newCarOffers={toJS(this.props.appStore.quotingStore.newCarOffers)}
                vehicleCondition={this.props.vehicleCondition}
                onProceedToFinanceNavigator={this.props.onProceedToFinanceNavigator}
              />
            </div>
          ) : (
            <NoQuotes />
          )}
          <div>
            <div className="quoteResults__cancelButton" key="cancel">
              <Button buttonStyle="cancel" type="button" onClick={this.props.onCancel}>
                {this.props.t('QuoteResults.cancel')}
              </Button>
            </div>
          </div>
        </PanelContent>
      </Panel>
    );
  }
}

QuoteResults.propTypes = {
  quotes: PropTypes.array.isRequired,
  hasAdjustedBalloon: PropTypes.bool,
  quotesForCompare: PropTypes.array,
  isLoading: PropTypes.bool,
  onChangeCosts: PropTypes.func.isRequired,
  onCompare: PropTypes.func,
  onProceed: PropTypes.func,
  proceedingState: PropTypes.string,
  onQuoteSelect: PropTypes.func,
  onProductNameClick: PropTypes.func,
  onCancel: PropTypes.func,
  enableShowroomCfc: PropTypes.bool,
  onCheckEligibilityClick: PropTypes.func,
  hasCheckedEligibility: PropTypes.bool,
  personalScorePercentage: PropTypes.number,
  craScorePercentage: PropTypes.number,
  chosenQuoteId: PropTypes.string,
  onSave: PropTypes.func,
  onEligibilityMatchClick: PropTypes.func,
  onEligibilitySummaryClick: PropTypes.func,
  isQuickQuote: PropTypes.bool,
  isRequote: PropTypes.bool,
  isCustomerQuote: PropTypes.bool,
  onSortQuotes: PropTypes.func,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  onSendDeal: PropTypes.func,
  enableSendDeal: PropTypes.bool,
  vehicleClass: PropTypes.string,
  appStore: PropTypes.object,
  customerType: PropTypes.string,
  showCombinedQuoteClick: PropTypes.func,
  proceedToBreakDown: PropTypes.func,
  onProceedToFinanceNavigator: PropTypes.func
};

export default withTranslation('Quoting')(inject('appStore')(observer(QuoteResults)));
