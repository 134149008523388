import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { lowercaseFirstLetter } from '~/components/Common/utils';
import {
  DisplayQuoteFormat,
  EligibilityOffered,
  FinanceNavigatorEligibilityDataType,
  FinanceNavigatorResultDataType,
  QuoteCardVariantType,
  QuoteCardVariantTypeEnum
} from '../../types';
import FinanceNavigatorEligibilityModal from '~/components/Quoting/modals/FinanceNavigatorEligibilityModal/FinanceNavigatorEligibilityModal';
import { financeScanResultsIvendiStatusViewDetailsSelected } from '~/lib/Avo';
import './quoteCardStatus.scss';
import { useParams } from 'react-router-dom';

export interface FinanceNavigatorStatusProps {
  funder: string;
  variant: QuoteCardVariantType;
  displayQuote: DisplayQuoteFormat;
  eligibility: FinanceNavigatorEligibilityDataType;
  quote: FinanceNavigatorResultDataType;
}

const FinanceNavigatorStatus = ({ displayQuote, eligibility, funder, variant, quote }: FinanceNavigatorStatusProps) => {
  const [isFNEligibilityModalOpen, setIsFNEligibilityModalOpen] = useState(false);
  const { dealershipId } = useParams();

  const handleOpenFNEligibilityModal = () => {
    financeScanResultsIvendiStatusViewDetailsSelected({
      financeQuoteMonthlyPayment: quote.FollowingPayments,
      financeQuoteProductType: quote.FinanceType,
      financeQuoteProductId: quote.ProductId,
      dealershipId: dealershipId ?? '',
      dealershipTown: undefined,
      financeQuoteId: quote.QuoteId,
      financeFunderCode: quote.FunderCode
    });
    setIsFNEligibilityModalOpen(true);
  };

  const handleCloseFNEligibilityModal = () => {
    setIsFNEligibilityModalOpen(false);
  };

  useEffect(() => {
    // Open the modal with eligibility details on arrival if the funder is Blackhorse
    // This is a temporary solution and will not work once we have multiple quotes on the page
    // Show the modal only on the finance navigator results page (and not the profile page)
    if (funder === 'BLA' && variant === QuoteCardVariantTypeEnum.FinanceNavigatorCheck) {
      setTimeout(() => {
        setIsFNEligibilityModalOpen(true);
      }, 100);
    }
  }, []);

  const { t } = useTranslation('QuoteCard');
  // eligibility.Data?.Eligibility will only be null on errored scan results which we currently do not display
  const eligibilityOffered = eligibility.Data?.Eligibility || EligibilityOffered.Unknown;
  return (
    <div
      className={`quoteCard__cell quoteCardStatus quoteCardStatus--${lowercaseFirstLetter(eligibilityOffered)}`}
      data-th="finance-navigator-status"
    >
      <span className="quoteCardStatus__statusText">
        {t(`FinanceNavigatorStatus.${lowercaseFirstLetter(eligibilityOffered)}`)}
      </span>

      <button
        className="quoteCardStatus__viewDetails"
        onClick={handleOpenFNEligibilityModal}
        data-th="financeNavigatorStatus__viewDetailsButton"
      >
        {t('ApplicationStatus.view_details')}
      </button>

      <FinanceNavigatorEligibilityModal
        isOpen={isFNEligibilityModalOpen}
        onClose={handleCloseFNEligibilityModal}
        quote={displayQuote}
        eligibility={eligibility}
      />
    </div>
  );
};

export default FinanceNavigatorStatus;
