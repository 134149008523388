import { ApplicationQuote } from '~/types/application';
import { FinanceNavigatorResultDataType, PreApprovalType, QuoteTypes } from '../types';

export const isFnEligibilityQuote = (quote: QuoteTypes | ApplicationQuote) => {
  return quote?.PreApprovalType === PreApprovalType.EligibilityCheck;
};
export const isFnRateForRiskQuote = (quote: QuoteTypes | ApplicationQuote) => {
  return quote?.PreApprovalType === PreApprovalType.RateForRisk;
};
export const isFnTypeQuote = (quote: QuoteTypes | ApplicationQuote) => {
  return isFnEligibilityQuote(quote) || isFnRateForRiskQuote(quote);
};
export const isInterceptorPreApprovalQuote = (quote: QuoteTypes | ApplicationQuote) => {
  return quote.SupportsPreApproval === true;
};
export const isFinanceNavigatorEligibilityQuote = (
  quote: QuoteTypes | ApplicationQuote
): quote is FinanceNavigatorResultDataType => {
  const validPreApprovalTypes = new Set([PreApprovalType.EligibilityCheck, PreApprovalType.RateForRisk]);
  return (
    (quote as FinanceNavigatorResultDataType).Eligibility !== undefined &&
    quote.PreApprovalType !== undefined &&
    validPreApprovalTypes.has(quote.PreApprovalType)
  );
};
