import { useCallback, useState } from 'react';
import FormFooter from '~/components/Common/Form/FormFooter';
import Panel from '~/components/Common/Panel';
import PanelContent from '~/components/Common/PanelContent';
import PanelHeader from '~/components/Common/PanelHeader';
import { FinanceNavigatorResultDataType } from '~/components/QuoteCard/types';
import { useNavigate } from '~/hooks/useNavigate';
import { usePubnubChannel } from '~/utils/dealershipEventEmitter';
import {
  FinanceNavigatorResultMessage,
  FinanceNavigatorResultMessageSchema
} from '../types/financeNavigatorResultMessage';
import './financeNavigatorResultsContainer.scss';
import { mapToFinanceNavigatorResultQuote } from '../utils/financeNavigatorUtils';
import { useTranslation } from 'react-i18next';
import { useTrackPageViewed } from '~/tracking/hooks';
import { useParams } from 'react-router-dom';
import QuoteResultsToolbar from '~/components/Quoting/components/QuoteResultsToolbar/QuoteResultsToolbar';
import QuotesListTable from '~/components/Quoting/components/QuotesListTable';
import { FixLater } from '~/types/basic';
import { financeScanResultsEditQuoteSelected } from '~/lib/Avo';

export interface FinanceNavigatorResultsContainerProps {
  scanId: string;
  quoteIds: string[];
  vehicle: FixLater;

  // A lot of these props come directly from the Redux state, shown in
  // the mapStateToProps here: src/components/Quoting/containers/QuoteModuleContainer.js
  // We can consider removing them as props and calling the state directly from where it's being used and reduce prop drilling

  // Both header and list related props
  customerType: string; // In some places it comes from an application and the others from the customer page data

  // To control results header actions - try to move as many away from props and derive from state
  onCompare: FixLater;
  onChangeCosts: FixLater; // Edit quotes

  // To render quotes list - try to move as many away from props and derive from state
  chosenQuoteId: string;
  hasAdjustedBalloon: boolean;
  proceedToBreakDown: FixLater; // Switches to the LOANS_BREAKDOWN_VIEW - should be able to do straight with state
  onQuoteSelect: FixLater;
  onProductNameClick: FixLater;
  onProceed: FixLater;
  onSave: FixLater;
  onSortQuotes: FixLater;
  sortField: FixLater;
  sortDirection: FixLater;
  // These two just what parent container the QuoteModule has
  // Maybe we can replace them by a QuoteModuleVariant and
  // derive that from the browserURL and set the variant that way rather than props - could be a hook
  isQuickQuote: boolean;
  isCustomerQuote: boolean;
  // This is mainly used in the quotesReducer to manage Save quote and Create application HTTP actions
  // Maybe it could be replaced with reactQuery or a simpler state and referenced directly in the component, rather than via props
  proceedingState: string;
}

export const FinanceNavigatorResultsContainer = (props: FinanceNavigatorResultsContainerProps) => {
  const { scanId, onProceed, onChangeCosts } = props;

  const navigate = useNavigate();
  const { dealershipId } = useParams();
  const { t } = useTranslation('FinanceNavigator');

  const [showSchemes, setShowSchemes] = useState<boolean>(false);
  const [showFullQuote, setShowFullQuote] = useState<boolean>(false);
  const [showAllQuotes, setShowAllQuotes] = useState<boolean>(false);

  const [lastMessage, setLastMessage] = useState<FinanceNavigatorResultMessage>();
  const [quotes, setQuotes] = useState<FinanceNavigatorResultDataType[]>([]);

  useTrackPageViewed('Finance Navigator - Results', dealershipId!);

  const handleMessage = useCallback(
    (m: unknown) => {
      try {
        const message = FinanceNavigatorResultMessageSchema.parse(m);
        setLastMessage(message);
        setQuotes(message.Results?.filter((x) => x.IsSuccess).map(mapToFinanceNavigatorResultQuote) || []);
      } catch (e) {
        console.error('An error occured processing pubnub message', { error: e, rawMessage: m });
      }
    },
    [setLastMessage, setQuotes]
  );

  const handleProceed = useCallback(
    (quote: FinanceNavigatorResultDataType) => {
      onProceed(quote, { customerId: lastMessage?.ConsumerId, vehicleId: lastMessage?.VehicleId });
    },
    [onProceed, lastMessage]
  );

  const handleEditQuote = useCallback(() => {
    if (quotes.length !== 0 && dealershipId) {
      const firstQuote = quotes[0];
      financeScanResultsEditQuoteSelected({
        financeQuoteMonthlyPayment: firstQuote.FollowingPayments,
        financeQuoteProductType: firstQuote.FinanceType,
        financeQuoteProductId: firstQuote.ProductId,
        dealershipId: dealershipId,
        dealershipTown: undefined,
        financeQuoteId: firstQuote.QuoteId,
        financeFunderCode: firstQuote.FunderCode
      });
    }
    onChangeCosts();
  }, [onChangeCosts, quotes, dealershipId]);

  usePubnubChannel(scanId, handleMessage);

  return (
    <Panel className="financeNavigatorResults__panel">
      {quotes.length === 0 && (
        <div data-th="financeNavigatorResults__fetchingQuotes" className="fetchingResults">
          {t('FinanceNavigatorResults.fetching_quotes_from_lenders')}
        </div>
      )}
      {lastMessage && quotes.length !== 0 && (
        <>
          <PanelHeader>{t('FinanceNavigatorResults.x_results', { x: quotes.length })}</PanelHeader>
          <QuoteResultsToolbar
            // onCheckEligibility={onCheckEligibilityClick} // Not needed - it's for CFC
            // onSendDeal={this.handleSendDeal} // Do we need this? Maybe not since it's not a regular quote?
            // hasQuoteSelected={hasQuoteSelected} // Not needed for now
            hasCheckedEligibility={true}
            customerType={props.customerType}
            onEditQuote={handleEditQuote}
            enableSendDeal={false}
            onToggleShowSchemes={setShowSchemes} // Do we need this, Schemes?
            showSchemes={showSchemes} // Do we need this, Schemes?
            onToggleShowFullQuotes={setShowFullQuote}
            showFullQuote={showFullQuote}
            onCompareQuotes={props.onCompare}
          />
          <PanelContent noPadding>
            <QuotesListTable
              // hasCheckedEligibility={props.hasCheckedEligibility} // not needed - it's for CFC
              // onEligibilityMatchClick={props.onEligibilityMatchClick} // not needed - it's for CFC
              // showCombinedQuoteClick={this.props.showCombinedQuoteClick} // Not needed - this is for BNP and we shouldn't see combined quotes in this view
              // newCarOffers={props.newCarOffers} // Not sure if this is needed for CFC checks or what the functionality is for
              // onProceedToFinanceNavigator={props.onProceedToFinanceNavigator} // not needed - we've already proceeded and are past that stage
              quotes={quotes}
              hasAdjustedBalloon={props.hasAdjustedBalloon} // Do we need this?
              onQuoteSelect={props.onQuoteSelect}
              onProductNameClick={props.onProductNameClick} // Do we need this?
              schemesVisible={showSchemes}
              onProceed={handleProceed}
              onSave={props.onSave}
              isQuickQuote={props.isQuickQuote}
              isCustomerQuote={props.isCustomerQuote}
              onSortQuotes={props.onSortQuotes}
              sortField={props.sortField}
              sortDirection={props.sortDirection}
              proceedingState={props.proceedingState}
              chosenQuoteId={props.chosenQuoteId}
              vehicleClass={props.vehicle.Class.toLowerCase()}
              proceedToBreakDown={props.proceedToBreakDown}
              showFullQuote={showFullQuote}
              vehicleCondition={props.vehicle.Condition.toLowerCase()}
              onRequote={props.onChangeCosts}
            />
            <p data-th="financeNavigatorResults__moreQuotesIndicator" className="fetchingMoreResults">
              {lastMessage.Status !== 'Complete'
                ? t('FinanceNavigatorResults.fetching_quotes_from_lenders')
                : t('FinanceNavigatorResults.no_more_quotes_from_lenders')}
            </p>
            <FormFooter
              onCancel={
                lastMessage.ConsumerId
                  ? () => navigate(`/d/${lastMessage.DealershipId}/consumers/${lastMessage.ConsumerId}`)
                  : undefined
              }
              hideSubmit
            />
          </PanelContent>
        </>
      )}
    </Panel>
  );
};
