import FinanceQuote from './financeQuote';
import FinanceApplication from './financeApplication';
import FinanceNavigatorCheck from './financeNavigatorCheck';
import SavedFinanceNavigatorCheck from './financeNavigatorCheck';
import SavedQuote from './savedQuote';
import CarFinanceCheck from './carFinanceCheck';
import SelfServiceDeals from './selfServiceDeals';
import SendDeal from './sendDeal';
import Requote from './reQuote';
import { QuoteCardVariantType } from '../../types';
import { QuoteCardConfig, QuoteCardConfigProps } from '../default';

export default {
  FinanceQuote,
  FinanceApplication,
  FinanceNavigatorCheck,
  SavedFinanceNavigatorCheck,
  SavedQuote,
  CarFinanceCheck,
  SelfServiceDeals,
  SendDeal,
  Requote
} as Record<QuoteCardVariantType, ({ quote, application }: QuoteCardConfigProps) => QuoteCardConfig>;
