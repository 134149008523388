import { CustomerFinanceApplication } from '~/types/application';
import { QuoteTypes } from '../types';

export type QuoteCardConfig = {
  showCfcStatus: boolean;
  showCheckbox: boolean;
  showCreatedDate: boolean;
};
export type QuoteCardConfigProps = {
  quote: QuoteTypes;
  application?: CustomerFinanceApplication;
};

const defaultConfig: QuoteCardConfig = {
  showCfcStatus: false,
  showCheckbox: false,
  showCreatedDate: false
};

export default defaultConfig;
