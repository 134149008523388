import { FinanceNavigatorResultDataType } from '~/components/QuoteCard/types';
import { FinanceNavigatorResult } from '../types/financeNavigatorResultMessage';
import {
  FinanceNavigatorForm,
  FinanceNavigatorFormAddressHistory,
  FinanceNavigatorFormAffordabilityDetails,
  FinanceNavigatorFormEmploymentHistory,
  FinanceNavigatorFormPersonalDetails
} from '../types/financeNavigatorForm';
import { PreFillFinanceScanForCustomerResponse } from '~/api/financeScan/types';
import moment from 'moment';

export const mapToFinanceNavigatorResultQuote = (result: FinanceNavigatorResult): FinanceNavigatorResultDataType => {
  const { PersonalisedQuote, ...Eligibility } = result;
  return { ...PersonalisedQuote, Eligibility };
};

export const preFillPersonalDetails = (
  formData: FinanceNavigatorForm,
  preFillData: PreFillFinanceScanForCustomerResponse,
  setPersonalDetails: (personalDetails: FinanceNavigatorFormPersonalDetails) => void
) => {
  const { Identity, ContactInfo, NoOfDependants, MaritalStatus } = preFillData;

  if (!Identity && !ContactInfo && !NoOfDependants && !MaritalStatus) {
    return;
  }

  const personalDetails = { ...formData.PersonalDetails };

  if (Identity) {
    const { Names, Dob } = Identity;
    if (Names) {
      personalDetails.Title = Names.Title;
      personalDetails.FirstName = Names.Firstname;
      personalDetails.MiddleNames = Names.Middlenames ?? '';
      personalDetails.LastName = Names.Surname;
    }
    if (Dob) {
      personalDetails.DateOfBirth = moment(Dob, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }
  }

  if (ContactInfo) {
    const { Email, MobileTelephone } = ContactInfo;
    if (Email) {
      personalDetails.Email = Email.Address;
    }
    if (MobileTelephone) {
      personalDetails.MobileTelephone = MobileTelephone;
    }
  }

  if (NoOfDependants) {
    personalDetails.NoOfDependants = NoOfDependants;
  }

  if (MaritalStatus) {
    personalDetails.MaritalStatus = MaritalStatus;
  }

  setPersonalDetails(personalDetails);
};

export const preFillAddressHistory = (
  formData: FinanceNavigatorForm,
  preFillData: PreFillFinanceScanForCustomerResponse,
  setAddressHistory: (addressHistory: FinanceNavigatorFormAddressHistory) => void
) => {
  const { AddressHistory } = preFillData;
  if (AddressHistory) {
    const addressHistory = { ...formData.AddressHistory };
    addressHistory.Items = AddressHistory.map((address) => ({
      BuildingName: address.BuildingName,
      BuildingNumber: address.BuildingNumber,
      SubBuilding: address.SubBuilding,
      Street: address.Street,
      District: address.District,
      PostTown: address.PostTown,
      Postcode: address.Postcode,
      Residency: address.RawResidency ?? '',
      TimeAtAddress: {
        Months: (address.MonthsAtAddress ?? 0) % 12,
        TotalMonths: address.MonthsAtAddress ?? 0,
        Years: Math.floor((address.MonthsAtAddress ?? 0) / 12)
      }
    }));
    addressHistory.TotalMonths = AddressHistory.reduce((left, right) => left + (right.MonthsAtAddress ?? 0), 0);
    setAddressHistory(addressHistory);
  }
};

export const preFillEmploymentHistory = (
  formData: FinanceNavigatorForm,
  preFillData: PreFillFinanceScanForCustomerResponse,
  setEmploymentHistory: (employmentHistory: FinanceNavigatorFormEmploymentHistory) => void
) => {
  const { EmploymentHistory } = preFillData;
  if (EmploymentHistory) {
    const employmentHistory = { ...formData.EmploymentHistory };
    employmentHistory.Items = EmploymentHistory.map((employment) => ({
      OccupationBasis: employment.RawStatus,
      OccupationType: employment.RawSkillLevel,
      TimeAtEmployment: {
        Months: (employment.MonthsInJob ?? 0) % 12,
        TotalMonths: employment.MonthsInJob ?? 0,
        Years: Math.floor((employment.MonthsInJob ?? 0) / 12)
      }
    }));
    employmentHistory.TotalMonths = EmploymentHistory.reduce((left, right) => left + (right.MonthsInJob ?? 0), 0);
    setEmploymentHistory(employmentHistory);
  }
};

export const preFillAffordabilityDetails = (
  formData: FinanceNavigatorForm,
  preFillData: PreFillFinanceScanForCustomerResponse,
  setAffordabilityDetails: (affordabilityDetails: FinanceNavigatorFormAffordabilityDetails) => void
) => {
  const { AffordabilityDetails } = preFillData;
  if (AffordabilityDetails) {
    const affordabilityDetails = { ...formData.AffordabilityDetails };
    affordabilityDetails.Income.GrossAnnual = AffordabilityDetails.GrossAnnualIncome;
    affordabilityDetails.Income.NetMonthly = AffordabilityDetails.NetMonthlyIncome;
    affordabilityDetails.Income.HasOtherHouseholdIncome = AffordabilityDetails.HasOtherHouseholdIncome;
    affordabilityDetails.Income.OtherHouseholdIncome = AffordabilityDetails.OtherHouseholdIncome;
    affordabilityDetails.MonthlyExpenditure.MortgageOrRental = AffordabilityDetails.MortgageOrRentExpenditure;
    affordabilityDetails.MonthlyExpenditure.Other = AffordabilityDetails.OtherCommittedMonthlyOutgoings;
    affordabilityDetails.PersonalCircumstances.ForeseeFutureDownturnInFinances =
      AffordabilityDetails.ForeseeFutureDownturnInFinances;
    affordabilityDetails.PersonalCircumstances.ForeseenYearlyIncome = AffordabilityDetails.ForeseenYearlyIncome;
    affordabilityDetails.PersonalCircumstances.DownturnReason = AffordabilityDetails.DownturnReason;
    affordabilityDetails.ReplacementLoan.IsReplacementLoan = AffordabilityDetails.ReplacementLoan;
    affordabilityDetails.ReplacementLoan.DetailsOfReplacementLoan = AffordabilityDetails.ReplacementLoanAmount;
    setAffordabilityDetails(affordabilityDetails);
  }
};
